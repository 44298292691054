import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionFooterTop,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  ImageCarousel,
  UserNav,
  Modal,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import SectionDiscover from '../../components/SectionDiscover/SectionDiscover';
import SectionTopRead from '../../components/SectionTopRead/SectionTopRead';
import Wave from '../../components/Wave/Wave';

import Testimonialslider from '../../components/Testimonial/Testimonial';
import { withViewport } from '../../util/contextHelpers';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    viewport,
  } = props;
  const { width } = viewport;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
          <UserNav selectedPageName="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} width={width} />
          </div>

          <ul className={css.sections}>
            <li className={`${css.section} ${css.abtsecrow}`}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations location={location} width={width} />
              </div>
              <Wave />
            </li>
            <li className={css.section}>
              <div className={`${css.sectionContent}  ${css.dnerow}`}>
                <SectionDiscover history={history} location={location} />
              </div>

              <Wave />
            </li>
            <li className={css.section}>
              <div className={`${css.sectionContent}  ${css.tratrow}`}>
                <SectionTopRead history={history} location={location} />
              </div>
            </li>
            <li className={css.section}>
              <div className={`${css.sectionContent} ${css.tratrow} ${css.hatwrow}`}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                  currentUser={currentUser}
                />
              </div>
              <Wave />
            </li>
            <li className={css.section}>
              <div className={`${css.sectionContent} ${css.testislider}`}>
                <Testimonialslider
                  history={history}
                  location={location}
                  currentUser={currentUser}
                />
              </div>
              {/* <Wave /> */}
            </li>
            <li className={`${css.section} ${css.footlast}`}>
              <SectionFooterTop className={css.hero} history={history} location={location} />
              <Wave />
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <Modal isOpen={false} onManageDisableScrolling={() => {}}>
        {' '}
        jdfskjfdksdjfksdjf skdjbfsjhdf sdkfjbskdf skdjf ksdj fksjd fksj dfksjd fksj dfk
      </Modal>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
