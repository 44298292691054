import React, { useState } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionFooterTop,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  ImageCarousel,
  Button,
  UserNav,
} from '../../components';
import consultIcon from '../../assets/consult.png';
import { TopbarContainer } from '../../containers';
import { MdLocationOn } from 'react-icons/md';
import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import revpaula from '../../assets/revpaula.png';
import revrory from '../../assets/revrory.png';
import makemov from '../../assets/makemov.png';
import perkm2 from '../../assets/perkm2.png';
import perkm1 from '../../assets/perkm1.png';
import perkm3 from '../../assets/perkm3.png';
import revrachel from '../../assets/revrachel.png';
import php1 from '../../assets/php1.png';
import php2 from '../../assets/php2.png';
import php3 from '../../assets/php3.png';
import php4 from '../../assets/php4.png';
import php5 from '../../assets/php5.png';
import { ReactComponent as Need1 } from '../../assets/need1.svg';
import { ReactComponent as Need2 } from '../../assets/need2.svg';
import { ReactComponent as Need3 } from '../../assets/need3.svg';
import { ReactComponent as Need4 } from '../../assets/need4.svg';
import { ReactComponent as Need5 } from '../../assets/need5.svg';
import { ReactComponent as Need6 } from '../../assets/need6.svg';
import perkS1 from '../../assets/perkS1.png';
import perkS2 from '../../assets/perkS2.png';
import perkS3 from '../../assets/perkS3.png';
import furthrTogathr from '../../assets/furthrTogathr.png';
import css from './ListMyBusinessPage.module.css';
import SubscriptionProducts from '../../components/SubscriptionProducts/SubscriptionProducts';
import SectionDiscover from '../../components/SectionDiscover/SectionDiscover';
import SectionTopRead from '../../components/SectionTopRead/SectionTopRead';
import Wave from '../../components/Wave/Wave';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { IoCheckmark } from 'react-icons/io5';
import Testimonialslider from '../../components/Testimonial/Testimonial';
import MembershipPlans from '../../components/MembershipPlans/MembershipPlans';
import PlanDetails from '../../components/PlanDeails/PlanDetails';
import BusinessFormContainer from '../../components/BusinessFormContainer/BusinessFormContainer';
import Faqs from '../../components/Faqs/Faqs';
import { withViewport } from '../../util/contextHelpers';
import StartBusiness from '../../components/StartBusiness/StartBusiness';
import BusinessHows from '../../components/BusinessHows/BusinessHows';
import classNames from 'classnames';
import Slider from 'react-slick';
import SwitchUserModal from '../../components/SwitchUserModal/SwitchUserModal';

const ViewWithLayout = props => {
  const {
    firstHeading,
    mainHeading,
    info,
    liContent,
    mainImg,
    logoText,
    logoIcon,
    reverseMode,
    secondImg,
  } = props;
  return (
    <div className={classNames(css.perkCrd, { [css.revPrkcrd]: reverseMode })}>
      <div className={css.perkDiv1}>
        <p>{firstHeading}</p>
        <p>{mainHeading}</p>
        <p>{info}</p>
        <ul>
          {liContent.map(c => (
            <li>
              <IoCheckmarkCircleOutline />
              {c}
            </li>
          ))}
        </ul>
      </div>
      <div className={css.perkDiv2}>
        <div className={css.plogodv}>
          <img src={mainImg} className={css.mainImg} />
          <div className={css.plogo}>
            <span>{logoText}</span>
            {logoIcon}
          </div>
          <img src={secondImg} className={css.secImg} />
        </div>
      </div>
    </div>
  );
};
const PlanCard = props => {
  const {
    heading,
    priceText,
    info,
    includes,
    liContent,
    isFreeCard,
    button,
    otherPriceText,
  } = props;
  const [mPlan, setMPlan] = useState('annual');
  return (
    <div className={classNames(css.planCrd, { [css.freeplncrd]: isFreeCard })}>
      {!isFreeCard && (
        <div className={css.switchCont}>
          <span>Annual</span>
          <button
            className={css.switchBtn}
            onClick={e => setMPlan(mPlan === 'annual' ? 'monthly' : 'annual')}
          >
            <span className={css.switchBody}>
              <span
                className={classNames(css.switch, {
                  [css.monthActSwitch]: mPlan === 'monthly',
                  [css.annualActSwitch]: mPlan === 'annual',
                })}
              ></span>
            </span>
          </button>
          <span>Monthly</span>
        </div>
      )}
      <p className={css.planHead}>{heading}</p>
      {isFreeCard ? priceText : mPlan === 'annual' ? priceText : otherPriceText}
      <p className={css.planInfo}>{info}</p>
      {includes}
      <ul>
        {liContent.map(c => (
          <li>{c}</li>
        ))}
      </ul>
      {button}
    </div>
  );
};
const HelpedLogosSection = props => {
  const { children, width } = props;
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: width > 576 ? 3 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    // ],
  };
  return width < 768 ? (
    <div className={`profslider ${css.sectionIconsSlider}`}>
      <Slider {...settings}>{children}</Slider>
    </div>
  ) : (
    <div className={css.helpedLogosCont}>{children}</div>
  );
};
export const LisMyBusinessPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    viewport,
  } = props;
  const { width } = viewport;
  const [modalOpen, setModalOpen] = useState(false);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const reviews = [
    {
      author: 'Rachel – Dance School',
      img: revrachel,
      content:
        '“I don’t like marketing or spreadsheets. Now when I create listings they are bookable, tracked, and pushed to socials in one single move. Plus, I can still take cash payments. This is a great tool.”',
    },
    {
      author: 'Rory – Rugby Coaching',
      img: revrory,
      content:
        '“It’s so simple to use! I can run my coaching sessions on-the-go. I know who’s registered, paid and access attendee info in seconds. It’s so fast to upload an activity. This works great for me.”',
    },
    {
      author: 'Paula – Events & Camps',
      img: revpaula,
      content:
        '“It’s a pleasure to use, even from my mobile. We’re booking new customers and saving time hours every week tracking payments and emailing customers!”',
    },
  ];
  const needs = [
    {
      title: 'Marketing Done for You',
      icon: <Need1 />,
      content1:
        'Let your activities shine with viral shares, our ad campaigns, social boosts, enhanced word of mouth, SEO for Google, newsletters and more.',
      content2: 'Reach thousands of social media followers and benefit from our expert PR team.',
    },
    {
      title: 'Shiny Listings',
      icon: <Need2 />,
      content1:
        'Create beautiful listings you’ll be proud of, in under 3 minutes. Enjoy adding your own photos and details, hit publish - then we do the rest.',
      content2: 'Add listings for free and be seen from the start. No need for a separate website.',
    },
    {
      title: 'Flexible Bookings',
      icon: <Need3 />,
      content1: 'You deserve flexibility, so we offer our booking system or choose your own.',
      content2:
        'Express Bookings take online payments from just 2.5% commission and your buyers cover the Stripe fees.',
    },
    {
      title: 'Admin Less, Do More',
      icon: <Need4 />,
      content1: 'Get more joy from your activities and spend less time on admin.',
      content2:
        'Our fuss-free admin system manages ticketing, payments, attendees, registrations, medical info and contacts in one place, from any device.',
    },
    {
      title: 'Support & Community',
      icon: <Need5 />,
      content1:
        'Nobody knows kids’ activities like Activity Time and our friendly team are here to help you.',
      content2: 'Plus, get access to our thriving FREE Activity Provider Facebook Group.',
    },
    {
      title: 'Grow Your Way',
      icon: <Need6 />,
      content1:
        'Build up your activity business, your way, supported at every step with our marketing, reporting, and booking tools.',
      content2:
        'Our hiring board, specialising in seasonal and part-time staffing - is coming soon.',
    },
  ];
  const perks = [
    {
      firstHeading: 'Parent - Approved',
      mainHeading: 'One-click booking for happier customers',
      info:
        'We all know how active parents and teachers are, with that in mind, we’ve designed a way to save them time.',
      liContent: [
        'Easy registration, even on-the-go.',
        'Secure and automatic processing.',
        'Speedy checkout with saved family profiles.Year-round activities keep them returning',
        'Year-round activities keep them returning',
      ],
      mainImg: perkm1,
      logoText: "You're Booked",
      logoIcon: <IoCheckmark />,
      secondImg: perkS1,
    },
    {
      firstHeading: 'Business On-The-Go',
      mainHeading: 'Simplify your days, focus on what counts',
      info:
        'Free up time by keeping customer information and business tools in one place. Work from anywhere.',
      liContent: [
        'Flexible billing, with cash balance option',
        'Track real-time registrations and payments',
        'Contact customers individually or by group',
        'Manage unlimited activities easily, on-the-go',
        'Easily build your own customer lists',
        'Push activities to your socials with a click.',
      ],
      mainImg: perkm2,
      logoText: 'Bookings',
      logoIcon: <IoCheckmark />,
      reverseMode: true,
      secondImg: perkS2,
    },
    {
      firstHeading: 'Grow a loyal fan base',
      mainHeading: 'Great activities deserve to be booked and shared',
      info:
        'Parents love a recommendation, right? Help them share your activities and leave great reviews. Get found easily by showing up online when and where they are looking.',
      liContent: [
        'Thousands of parents searching activities',
        'Build trust with visible customer ratings',
        '‘Share’ button enhances word-of-mouth',
        'Published to socials & optimised for Google',
        'Benefit from having an expert marketing partner for your launches and last-minute spaces',
      ],
      mainImg: perkm3,
      logoText: 'Shared',
      logoIcon: <MdLocationOn />,
      secondImg: perkS3,
    },
  ];
  const howItWorks = [
    'Use your trial to list all your activities and unlimited tickets, profile, and T&C’s for free.',
    'Get found by families and schools searching for activities just like yours, just by hitting ‘publish’.',
    'Become a paid member to turbo-boost your activity rankings, offer 1-click bookings and grow sales. Or, stay free.',
  ];
  const plans = [
    {
      isFreeCard: true,
      heading: 'FREE Forever',
      colorCode: '#2ecc71',

      priceText: <p className={css.planPrice}>€0 Setup Fees *</p>,
      info:
        'For activity planners who run occasional activities. Benefit from our platform marketing, ticketing and admin features.',
      includes: <p className={css.planIncludes}>Includes:</p>,
      liContent: [
        '3 categories',
        '2 listings per month',
        'Unlimited tickets',
        'Express Booking, just 4% commission.',
        'Own Profile',
        "Own T&C's",
        'Direct bank payment',
        'Share on socials',
      ],
    },
    {
      colorCode: '#921b98',
      heading: 'Membership',
      priceText: (
        <p className={css.planPrice}>
          <span>€299</span> €199 billed yearly *
        </p>
      ),
      otherPriceText: <p className={css.planPrice}>€25 billed monthly *</p>,
      info:
        'For activity providers who want more free time. Unlock all marketing, ticketing and admin benefits, access all features.',
      includes: (
        <p className={css.mplanIncludes}>
          Includes: Everything in <span>FREE</span> plus
        </p>
      ),
      liContent: [
        '11 categories',
        'Unlimited listings',
        'Booking flexibility - your site or ours',
        'Express Booking, just 2.5% commission',
        'Best search result ranking',
        'Visible customer reviews',
        'Profiles optimised for Google',
        "Eligible for our What's On newsletter",
        'Flexible publishing - private & public',
        'Access marketing boosts',
        'Hiring board listings (coming soon)',
        'Edit & duplicate for faster publishing',
      ],
    },
  ];
  const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;
  const helpedLogos = [php5, php1, php2, php3, php4];
  const openSwitchAccount = currentUser?.id?.uuid && !isProvider;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
          <UserNav />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>

          <ul className={css.sections}>
            <li className={`${css.section} ${css.abtsecrow}`}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations location={location} width={width} />
              </div>
              <Wave />
            </li>
            <li className={css.section}>
              <div className={`${css.sectionContent}  ${css.dnerow}`}>
                <p className={css.redmainHeading}>What to take a look?</p>
                <p className={css.blsub}>
                  We understand <b>time is precious</b> and before you sign up, why not take a peek
                  at how our platform works:
                </p>
                <div className={css.wtlvdo}>
                  <video autoPlay controls src={'C:\\Users\\user\\Downloads\\New'} />
                </div>
                {openSwitchAccount ? (
                  <span onClick={e => setModalOpen(true)} className={css.getStartedLink}>
                    Get listings for FREE
                  </span>
                ) : (
                  <NamedLink name={'CreateListingPage'} className={css.getStartedLink}>
                    Get listings for FREE
                  </NamedLink>
                )}
              </div>
              {/* <Wave /> */}
            </li>
            <li className={`${css.section} ${css.whiteSection}`}>
              <div className={`${css.sectionContent}  ${css.dnerow}`}>
                <p className={css.mainHeading}>“If it worked for them, it could work for you.”</p>
                <p className={css.blsub}>
                  We love the positive benefits you offer our children, we want to do something good
                  for you! Built in partnership with expert providers and busy parents.
                </p>
                <div className={css.revCardCont}>
                  {reviews.map(r => (
                    <div className={css.revCrd} key={r.author}>
                      <img src={r.img} />
                      <p>{r.content}</p>
                      <p>{r.author}</p>
                    </div>
                  ))}
                </div>
              </div>
              {/* <Wave /> */}
            </li>
            <li className={`${css.section}`}>
              <div className={`${css.sectionContent} ${css.testislider}`}>
                <div className={css.tssliderrow}>
                  <div className={css.benifitContent}>
                    <h3 className={css.cardHeading}>Make Meaningful Moves</h3>
                    <p>
                      Mothers book over 70% of kids’ activities. By keeping your best customers, as
                      our north star, we are designing the best activity platform for them and you.
                    </p>
                  </div>
                  <img src={makemov} alt="" className={css.cardImg} />
                </div>
              </div>
              <Wave />
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <p className={css.redsub}>Features</p>
                <p className={css.mainHeading}>Run your activities the way you want to</p>
                <p className={css.descp}>
                  Want your marketing done for you? Want affordable ticketing? Need some freedom
                  from spreadsheets and tracking payments manually? Struggling with uploading
                  listings to your own website without IT help?
                </p>
                <p className={css.blsub}>We’re the platform that puts your needs first.</p>
                <div className={css.needcont}>
                  {needs.map(n => (
                    <div className={css.needcrd}>
                      <p>{n.title}</p>
                      {n.icon}
                      <p>{n.content1}</p>
                      <p>{n.content2}</p>
                    </div>
                  ))}
                </div>
                {openSwitchAccount ? (
                  <span onClick={e => setModalOpen(true)} className={css.getStartedLink}>
                    Get listings for FREE
                  </span>
                ) : (
                  <NamedLink name={'CreateListingPage'} className={css.getStartedLink}>
                    Get listings for FREE
                  </NamedLink>
                )}
              </div>
              <Wave colName={'#ffffff'} />
            </li>
            <li className={css.section}>
              <div className={`${css.sectionWithLayout} ${css.sectionContent}`}>
                {perks.map(p => (
                  <ViewWithLayout {...p} />
                ))}
              </div>
              <Wave />
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                {/* <MembershipPlans /> */}
                <p className={css.mainHeading}>How it Works</p>
                <p className={css.blsub}>We all need tools to help us work better.</p>
                <div className={css.hwitwcont}>
                  {howItWorks.map((h, i) => (
                    <div className={css.hwitwcrd}>
                      <span>Step {i + 1}</span>
                      <p>{h}</p>
                    </div>
                  ))}
                </div>
              </div>
              <Wave colName="#ffffff" />
            </li>
            <li className={css.section}>
              <div className={`${css.planSecCont} ${css.sectionContent}`}>
                <p className={css.redsub}>Pricing</p>
                <div className={css.pricingLogo}>
                  <p>FREE</p>
                  <p>Trial</p>
                  <p>FOR 1 MONTH</p>
                </div>
                <p className={css.mainHeading}>Start your 1-month FREE trial, today</p>
                <p className={css.descp}>
                  Built for where activity providers are now and where parents and teachers will
                  always find you.
                </p>
                <div className={css.planCont}>
                  {plans.map(p => (
                    <PlanCard
                      {...p}
                      button={
                        openSwitchAccount ? (
                          <span onClick={e => setModalOpen(true)} className={css.getStartedLink}>
                            Get Started
                          </span>
                        ) : (
                          <NamedLink name={'CreateListingPage'} className={css.getStartedLink}>
                            Get Started
                          </NamedLink>
                        )
                      }
                    />
                  ))}
                </div>
                <div className={css.ftfCont}>
                  <p>Flexible Ticketing & Fees:</p>
                  <p>
                    * Prices + vat. 2.5% on tickets booked using our booking option. Stripe Fees are
                    pre-set to be paid by ticket buyers. But, we’re flexible! If your activity is
                    already bookable you can move customers to your own site for ticketing and pay
                    those booking fees instead. Bookable categories on Activity Time include camps,
                    classes and workshops, this will expand with time. FREE tickets & trials can be
                    created and the ticketing fee will not be passed on to the ticket buyer.
                    Currency is euros.
                  </p>
                </div>
              </div>
              {/* <Wave colName={'#ffffff'} /> */}
            </li>
            <li className={css.section}>
              <div className={css.sectionContent} style={{ marginBottom: '60px' }}>
                <div className={css.furthrTogathr}>
                  <img src={furthrTogathr} />
                  <div>
                    <p className={css.redmainHeading}>Activities go further, together</p>
                    {openSwitchAccount ? (
                      <span onClick={e => setModalOpen(true)} className={css.getStartedLink}>
                        Get Started
                      </span>
                    ) : (
                      <NamedLink name={'CreateListingPage'} className={css.getStartedLink}>
                        Get Started
                      </NamedLink>
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li className={css.section}>
              <div
                className={css.sectionContent}
                style={{ marginTop: '50px', marginBottom: '180px' }}
              >
                <p className={css.blsub}>We understand getting support means a lot.</p>
                <p className={css.blsub}>Here’s who helped us:</p>
                <HelpedLogosSection width={width}>
                  {helpedLogos.map(l => (
                    <img src={l} />
                  ))}
                </HelpedLogosSection>
              </div>
              <Wave colName={'#f2f1ef'} />
            </li>
            <li className={`${css.greySection} ${css.section}`}>
              <div className={css.sectionContent} style={{ marginBottom: '30px' }}>
                <BusinessFormContainer />
              </div>
              {/* <Wave colName={'#f2f1ef'} /> */}
            </li>
            <li className={`${css.greySection} ${css.section}`}>
              <div className={css.sectionContent} style={{ marginBottom: '48px' }}>
                <Faqs />
              </div>
              <Wave colName={'#fff'} />
            </li>
            <li className={`${css.whiteSection} ${css.section}`}>
              <div className={css.sectionContent} style={{ marginBottom: '48px' }}>
                <div className={css.consultContainer}>
                  <h3>Want to skip the forms?</h3>
                  <img src={consultIcon} alt="" />
                  <span>Audrey Vance</span>
                  <span>Activity Time Founder</span>
                  <button type="button" className={css.button}>
                    Schedule a consultation
                  </button>
                  <span> Or call us at:</span>
                  <span style={{ marginTop: '8px', color: '#c93b55', fontSize: '20px' }}>
                    (353) 086 8036914
                  </span>
                </div>
              </div>
            </li>
            <li className={`${css.section} ${css.footlast}`}>
              <SectionFooterTop className={css.hero} history={history} location={location} />
              <Wave />
            </li>
          </ul>
          <SwitchUserModal
            modalOpen={modalOpen}
            isProvider={isProvider}
            setModalOpen={setModalOpen}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LisMyBusinessPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LisMyBusinessPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LisMyBusinessPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(LisMyBusinessPageComponent);

export default LisMyBusinessPage;
